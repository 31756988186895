<template>
    <div class="">
      <strong>{{ message.data.name }}:</strong> {{ message.data.message }} 
      <small >(<time-ago :datetime="message.timestamp" style="font-size: x-small;"></time-ago>)</small>
    </div>
  </template>
  
  <script>

import { TimeAgo } from 'vue2-timeago'
import 'vue2-timeago/dist/vue2-timeago.css'

  export default {
    name: 'message',
    props: ['message'],
    data() {
      return {};
    },
    components: {
        TimeAgo
    },
    computed: {

    }
  };
  </script>
  
  <style>
  </style>