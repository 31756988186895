<template>
     <div class="container has-background-white-ter">
    <section class="section is-primary pb-1" style="border: 1px solid black; height: 400px; overflow: scroll;">

        <Message v-for="(message, index) in history" :message="message" :key="index"></Message>
    </section>
      <div class="col-md-12">


          <div class="control">

            <input type="text" class="input" v-on:keyup.enter="sendMessage" name="message" id="message" v-model="message" placeholder="Enter Message">
          </div>
          <button class="button" @click.prevent="sendMessage">Send</button>

      </div>

    </div>
  </template>

  <script>


  import Message from '@/components/Message';

  /* eslint no-console: ["error", { allow: ["log", "warn", "error"] }] */


  const ls = require( 'local-storage')



  export default {
    components: {
      Message,
    },
    data() {
      return {
        message: '',

      };
    },
    mounted() {
      let now = (new Date()).getTime()

      ls("lastSeen", now)
      this.$store.state.lastSeenMessages = now;
    },
    beforeDestroy() {
      let now = (new Date()).getTime()
      ls("lastSeen", now)
      this.$store.state.lastSeenMessages = now;
    },
    methods: {

      sendMessage() {
        console.log("About to ")
        this.message = this.message.trim()

        if (this.message.length === 0) {
          return;
        }

        this.$store.state.channel.publish('mainroom', {
          name: this.name,
          message: this.message,
        });

        this.message = '';
      },
    },
    computed: {

      history() {
        console.log(this.$store.state.messages)
        return (this.$store.state.messages ?? []).slice().reverse()
      },
      name() {

        const x = this.$store.getters.currentUserDetails;

        return  x.Title +" " + x.FirstName + " "  + x.LastName

      },

      userDetails() {
      //console.log(this.$store.state.currentDomain)
      return this.$store.getters.currentUserDetails
      },

    },

  };
  </script>

  <style>
  </style>